import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const About = () => {
  return (
    <Layout>
      <SEO title="About SCE" />

      <Helmet
        bodyAttributes={{
          class: "bg-power-lines",
        }}
      />
      <div className="py-24 w-full px-4 md:px-0 md:w-3/5 mx-auto">
        <div className="border-b border-gray-800 mb-8 pb-8">
          <h1 className="uppercase font-bold tracking-wide mb-4 text-5xl">
            About Us
          </h1>
        </div>
        <article className="text-xl">
          <p className="mb-6">
            Southeastern Consulting Engineers, Inc. (Southeastern) was founded
            in 1961, and has specialized in serving the needs of municipal,
            industrial, and institutional electric systems for more than 55
            years. Southeastern serves 35 clients in the Southeast. All
            operations are carried out from one centrally located office in
            Charlotte, North Carolina. The professional engineers on our staff
            hold registration in Virginia, North Carolina and South Carolina,
            with various individuals each holding from one to three state
            registrations. All professional personnel are electrical by
            specialty.
          </p>
        </article>
      </div>
    </Layout>
  )
}

export default About
